<template>
  <img v-if="logo && logo.imageUrl" class="h-24 w-auto" :src="logo.imageUrl" alt="Aller Service" width="82" height="24">
</template>

<script lang="ts" setup>
import { LogoTransformer } from '@aller/nuxt-allerservice-sdk/transformers';
import type { OptimizelyContentResponse } from '@aller/nuxt-allerservice-sdk/types';

// Get the base start data.
const start = useState<OptimizelyContentResponse>('start');
const logo = LogoTransformer(start.value);
</script>
